import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, FormGroup, Input, CustomInput, Label, Spinner } from 'reactstrap';
import withRedirect from '../../hoc/withRedirect';

import {apiURL} from '../../config';

const LoginForm = ({ setToken, hasLabel }) => {
  // State
  const [pendingLogin, setPendingLogin] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setPendingLogin(true);

    // TODO - perform login

    fetch(`${apiURL}auth/login`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    }).then((resp) => {
      if (resp.status === 403) {
        throw new Error('Unauthorised');
      }
      return resp;
    }).then((resp) => resp.json()).then((resp) => {
      setToken(resp);
      // setting our token will modify our client state
      //  so nothing else to do here!
    }).catch((e) => {
      setPendingLogin(false);
      toast.error(`Failed to login: ${e.message}`);
      console.error(e);
    });
  };

  useEffect(() => {
    setIsDisabled(!username || !password);
  }, [username, password]);

  if (pendingLogin) {
    return (
      <Spinner color="primary" />
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Username</Label>}
        <Input
          placeholder={!hasLabel ? 'Username' : ''}
          value={username}
          onChange={({ target }) => setUsername(target.value)}
          type="text"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Password</Label>}
        <Input
          placeholder={!hasLabel ? 'Password' : ''}
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <CustomInput
            id="customCheckRemember"
            label="Remember me"
            checked={remember}
            onChange={({ target }) => setRemember(target.checked)}
            type="checkbox"
          />
        </Col>
        <Col xs="auto">
          {/* TODO <Link className="fs--1" to={`/authentication/${layout}/forget-password`}>
            Forget Password?
  </Link>*/}
        </Col>
      </Row>
      <FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          Log in
        </Button>
      </FormGroup>
    </Form>
  );
};

LoginForm.propTypes = {
  setToken: PropTypes.func.isRequired
};

export default withRedirect(LoginForm);
