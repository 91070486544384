import _buildData from './git.js';
export const buildData = _buildData;
export const version = buildData.hash;
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: true,
  isTopNav: false,
  isVertical: true,
  get isCombo() {
    return this.isVertical && this.isTopNav;
  },
  showBurgerMenu: true, // controls showing vertical nav on mobile
  currency: '£',
  isNavbarVerticalCollapsed: false,
  navbarStyle: 'transparent',
  // ThemeParks.wiki stuff
  siteTitle: 'ThemeParks.wiki',
  templatePath: '/template', // debug only
};
export const debugMode = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
export const apiURL = process.env.APIURL || "https://api.themeparks.wiki/";
export const apiVersion = process.env.APIVERSION || "v1";

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
