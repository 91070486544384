// helper class to get our auth token
import { useState } from 'react';

let jwt_token = null;

export function getToken() {
    if (jwt_token) return jwt_token;
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    jwt_token = userToken?.token;
    return jwt_token;
}

export default function useToken() {
    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        jwt_token = userToken;
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken.token);
    };

    return {
        setToken: saveToken,
        token
    };
}
