import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import authToken from '../authToken';

import AdminLogin from './AdminLogin';

import { Spinner } from 'reactstrap';

const AdminLayout = React.lazy(() => import('./AdminLayout'));

function AdminContainer({ location }) {
    const { token, setToken } = authToken();

    // console.log('Deleting Cache...');
    // caches.delete('api');

    if (token) {
        return <Suspense fallback={<Spinner />}>
            <AdminLayout location={location} />
        </Suspense>
    }

    return <AdminLogin setToken={setToken} />
}

AdminContainer.propTypes = { location: PropTypes.object.isRequired };

export default AdminContainer
