import { version, settings } from './config';

const sampleContentPath = settings.templatePath;


// DEBUG routes

export const homeRoutes = {
  name: 'Home',
  to: sampleContentPath,
  exact: true,
  icon: 'chart-pie',
  children: [
    {
      to: sampleContentPath,
      name: 'Dashboard',
      exact: true
    },
    { to: `${sampleContentPath}/dashboard-alt`, name: 'Dashboard alt' },
    { to: `${sampleContentPath}/feed`, name: 'Feed', exact: true },
    { to: `${sampleContentPath}/landing`, name: 'Landing' }
  ]
};

export const authenticationRoutes = {
  name: 'Authentication',
  to: `${sampleContentPath}/authentication`,
  icon: 'lock',
  children: [
    {
      to: `${sampleContentPath}/authentication/basic`,
      name: 'Basic',
      children: [
        { to: `${sampleContentPath}/authentication/basic/login`, name: 'Login' },
        { to: `${sampleContentPath}/authentication/basic/logout`, name: 'Logout' },
        { to: `${sampleContentPath}/authentication/basic/register`, name: 'Register' },
        { to: `${sampleContentPath}/authentication/basic/forget-password`, name: 'Forgot password' },
        { to: `${sampleContentPath}/authentication/basic/password-reset`, name: 'Reset password' },
        { to: `${sampleContentPath}/authentication/basic/confirm-mail`, name: 'Confirm mail' },
        { to: `${sampleContentPath}/authentication/basic/lock-screen`, name: 'Lock screen' }
      ]
    },
    {
      to: `${sampleContentPath}/authentication/card`,
      name: 'Card',
      children: [
        { to: `${sampleContentPath}/authentication/card/login`, name: 'Login' },
        { to: `${sampleContentPath}/authentication/card/logout`, name: 'Logout' },
        { to: `${sampleContentPath}/authentication/card/register`, name: 'Register' },
        { to: `${sampleContentPath}/authentication/card/forget-password`, name: 'Forgot password' },
        { to: `${sampleContentPath}/authentication/card/password-reset`, name: 'Reset password' },
        { to: `${sampleContentPath}/authentication/card/confirm-mail`, name: 'Confirm mail' },
        { to: `${sampleContentPath}/authentication/card/lock-screen`, name: 'Lock screen' }
      ]
    },
    {
      to: `${sampleContentPath}/authentication/split`,
      name: 'Split',
      children: [
        { to: `${sampleContentPath}/authentication/split/login`, name: 'Login' },
        { to: `${sampleContentPath}/authentication/split/logout`, name: 'Logout' },
        { to: `${sampleContentPath}/authentication/split/register`, name: 'Register' },
        { to: `${sampleContentPath}/authentication/split/forget-password`, name: 'Forgot password' },
        { to: `${sampleContentPath}/authentication/split/password-reset`, name: 'Reset password' },
        { to: `${sampleContentPath}/authentication/split/confirm-mail`, name: 'Confirm mail' },
        { to: `${sampleContentPath}/authentication/split/lock-screen`, name: 'Lock screen' }
      ]
    },
    {
      to: `${sampleContentPath}/authentication/wizard`,
      name: 'Wizard'
    }
  ]
};

export const ECommerceRoutes = {
  name: 'E commerce',
  to: `${sampleContentPath}/e-commerce`,
  icon: 'cart-plus',
  children: [
    { to: `${sampleContentPath}/e-commerce/products/list`, name: 'Product list' },
    { to: `${sampleContentPath}/e-commerce/products/grid`, name: 'Product grid' },
    { to: `${sampleContentPath}/e-commerce/product-details`, name: 'Product details' },
    { to: `${sampleContentPath}/e-commerce/orders`, name: 'Orders' },
    { to: `${sampleContentPath}/e-commerce/order-details`, name: 'Order details' },
    { to: `${sampleContentPath}/e-commerce/customers`, name: 'Customers' },
    { to: `${sampleContentPath}/e-commerce/shopping-cart`, name: 'Shopping cart' },
    { to: `${sampleContentPath}/e-commerce/checkout`, name: 'Checkout' },
    { to: `${sampleContentPath}/e-commerce/favourite-items`, name: 'Favourite items' }
  ]
};

export const pageRoutes = {
  name: 'Pages',
  to: `${sampleContentPath}/pages`,
  icon: 'copy',
  children: [
    { to: `${sampleContentPath}/pages/activity`, name: 'Activity' },
    { to: `${sampleContentPath}/pages/associations`, name: 'Associations' },
    { to: `${sampleContentPath}/pages/billing`, name: 'Billing' },
    { to: `${sampleContentPath}/pages/customer-details`, name: 'Customer details' },
    { to: `${sampleContentPath}/pages/event-detail`, name: 'Event detail' },
    { to: `${sampleContentPath}/pages/event-create`, name: 'Event create' },
    { to: `${sampleContentPath}/pages/events`, name: 'Events' },
    { to: `${sampleContentPath}/pages/faq`, name: 'Faq' },
    { to: `${sampleContentPath}/pages/invoice`, name: 'Invoice' },
    { to: `${sampleContentPath}/pages/invite-people`, name: 'Invite people' },
    { to: `${sampleContentPath}/pages/notifications`, name: 'Notifications' },
    { to: `${sampleContentPath}/pages/people`, name: 'People' },
    { to: `${sampleContentPath}/pages/pricing`, name: 'Pricing' },
    { to: `${sampleContentPath}/pages/pricing-alt`, name: 'Pricing alt' },
    { to: `${sampleContentPath}/pages/profile`, name: 'Profile' },
    { to: `${sampleContentPath}/pages/settings`, name: 'Settings' },
    { to: `${sampleContentPath}/pages/starter`, name: 'Starter' },
    {
      // TODO?
      to: '/errors',
      name: 'Errors',
      children: [{ to: '/errors/404', name: '404' }, { to: '/errors/500', name: '500' }]
    }
  ]
};
export const widgetsRoutes = {
  name: 'Widgets',
  to: `${sampleContentPath}/widgets`,
  exact: true,
  icon: 'poll'
};

export const calenderRoutes = {
  name: 'Calendar',
  to: `${sampleContentPath}/calendar`,
  exact: true,
  icon: 'calendar-alt'
};

export const chatRoutes = {
  name: 'Chat',
  to: `${sampleContentPath}/chat`,
  exact: true,
  icon: 'comments'
};

export const kanbanRoutes = {
  name: 'Kanban',
  to: `${sampleContentPath}/kanban`,
  exact: true,
  icon: ['fab', 'trello']
};

export const emailRoutes = {
  name: 'Email',
  to: `${sampleContentPath}/email`,
  icon: 'envelope-open',
  children: [
    { to: `${sampleContentPath}/email/inbox`, name: 'Inbox' },
    { to: `${sampleContentPath}/email/email-detail`, name: 'Email detail' },
    { to: `${sampleContentPath}/email/compose`, name: 'Compose' }
  ]
};

export const documentationRoutes = {
  name: 'Documentation',
  to: `${sampleContentPath}/documentation`,
  exact: true,
  icon: 'book'
};

export const changelogRoutes = {
  name: 'ChangeLog',
  to: `${sampleContentPath}/changelog`,
  exact: true,
  icon: 'code-branch',
  badge: {
    text: `v${version}`,
    color: 'soft-primary'
  }
};

export const componentRoutes = {
  name: 'Components',
  to: `${sampleContentPath}/components`,
  icon: 'puzzle-piece',
  children: [
    { to: `${sampleContentPath}/components/alerts`, name: 'Alerts' },
    { to: `${sampleContentPath}/components/accordions`, name: 'Accordions' },
    {
      to: `${sampleContentPath}/components/autocomplete`,
      name: 'Autocomplete'
    },
    { to: `${sampleContentPath}/components/avatar`, name: 'Avatar' },
    { to: `${sampleContentPath}/components/badges`, name: 'Badges' },
    { to: `${sampleContentPath}/components/backgrounds`, name: 'Backgrounds' },
    { to: `${sampleContentPath}/components/breadcrumb`, name: 'Breadcrumb' },
    { to: `${sampleContentPath}/components/buttons`, name: 'Buttons' },
    { to: `${sampleContentPath}/components/cards`, name: 'Cards' },
    {
      to: `${sampleContentPath}/components/cookie-notice`,
      name: 'Cookie notice'
    },
    { to: `${sampleContentPath}/components/collapses`, name: 'Collapses' },
    {
      to: `${sampleContentPath}/components/carousel`,
      name: 'Carousel'
    },
    { to: `${sampleContentPath}/components/dropdowns`, name: 'Dropdowns' },
    { to: `${sampleContentPath}/components/forms`, name: 'Forms' },
    { to: `${sampleContentPath}/components/listgroups`, name: 'List groups' },
    { to: `${sampleContentPath}/components/modals`, name: 'Modals' },
    { to: `${sampleContentPath}/components/navs`, name: 'Navs' },
    {
      to: `${sampleContentPath}/components`,
      name: 'Navbar',
      children: [
        { to: `${sampleContentPath}/components/navbars`, name: 'Default' },
        {
          to: `${sampleContentPath}/components/navbar-vertical`,
          name: 'Vertical'
        },
        {
          to: `${sampleContentPath}/components/navbar-top`,
          name: 'Top'
        },
        {
          to: `${sampleContentPath}/components/combo`,
          name: 'Combo'
        }
      ]
    },
    { to: `${sampleContentPath}/components/pageheaders`, name: 'Page headers' },
    { to: `${sampleContentPath}/components/paginations`, name: 'Paginations' },
    { to: `${sampleContentPath}/components/popovers`, name: 'Popovers' },
    { to: `${sampleContentPath}/components/progress`, name: 'Progress' },
    { to: `${sampleContentPath}/components/sidepanel`, name: 'Sidepanel' },
    { to: `${sampleContentPath}/components/spinners`, name: 'Spinners' },
    {
      to: `${sampleContentPath}/components/tab`,
      name: 'Tabs',
      badge: {
        text: `New`,
        color: 'soft-success'
      }
    },
    { to: `${sampleContentPath}/components/tables`, name: 'Tables' },
    { to: `${sampleContentPath}/components/tooltips`, name: 'Tooltips' }
  ]
};

export const pluginRoutes = {
  name: 'Plugins',
  to: `${sampleContentPath}/plugins`,
  icon: 'plug',
  children: [
    { to: `${sampleContentPath}/plugins/bulk-select`, name: 'Bulk select' },
    {
      to: `${sampleContentPath}/plugins/react-beautiful-dnd`,
      name: 'Beautiful DnD'
    },
    {
      to: `${sampleContentPath}/plugins`,
      name: 'Chart',
      children: [{ to: `${sampleContentPath}/plugins/chart`, name: 'Chart Js' }, { to: `${sampleContentPath}/plugins/echarts`, name: 'Echarts' }]
    },
    { to: `${sampleContentPath}/plugins/countup`, name: 'Countup' },
    { to: `${sampleContentPath}/plugins/code-highlight`, name: 'Code Highlight' },
    { to: `${sampleContentPath}/plugins/datetime`, name: 'Datetime' },
    { to: `${sampleContentPath}/plugins/dropzone`, name: 'Dropzone' },
    { to: `${sampleContentPath}/plugins/emoji-mart`, name: 'Emoji Mart' },
    { to: `${sampleContentPath}/plugins/font-awesome`, name: 'Font Awesome' },
    {
      to: `${sampleContentPath}/plugins/calendar-example`,
      name: 'Full Calendar'
    },
    { to: `${sampleContentPath}/plugins/image-lightbox`, name: 'Image lightbox' },
    { to: `${sampleContentPath}/plugins/lottie`, name: 'Lottie' },
    {
      to: `${sampleContentPath}/plugins`,
      name: 'Map',
      children: [
        { to: `${sampleContentPath}/plugins/leaflet-map`, name: 'Leaflet map' },
        { to: `${sampleContentPath}/plugins/google-map`, name: 'Google map' },
        { to: `${sampleContentPath}/plugins/echart-map`, name: 'Echart Map' }
      ]
    },
    { to: `${sampleContentPath}/plugins/plyr`, name: 'Plyr' },
    { to: `${sampleContentPath}/plugins/progressbar`, name: 'Progressbar' },
    { to: `${sampleContentPath}/plugins/react-hook-form`, name: 'React Hook Form' },
    {
      to: `${sampleContentPath}/plugins/react-bootstrap-table2`,
      name: 'BS Table2'
    },
    { to: `${sampleContentPath}/plugins/select`, name: 'Select' },
    { to: `${sampleContentPath}/plugins/slick-carousel`, name: 'Slick Carousel' },
    { to: `${sampleContentPath}/plugins/scroll-bar`, name: 'Scroll Bar' },
    { to: `${sampleContentPath}/plugins/toastify`, name: 'Toastify' },
    { to: `${sampleContentPath}/plugins/typed`, name: 'Typed' },
    { to: `${sampleContentPath}/plugins/wysiwyg`, name: 'WYSIWYG editor' }
  ]
};

export const utilityRoutes = {
  name: 'Utilities',
  to: `${sampleContentPath}/utilities`,
  icon: ['fab', 'hotjar'],
  children: [
    { to: `${sampleContentPath}/utilities/borders`, name: 'Borders' },
    { to: `${sampleContentPath}/utilities/clearfix`, name: 'Clearfix' },
    { to: `${sampleContentPath}/utilities/closeIcon`, name: 'Close icon' },
    { to: `${sampleContentPath}/utilities/colors`, name: 'Colors' },
    { to: `${sampleContentPath}/utilities/display`, name: 'Display' },
    { to: `${sampleContentPath}/utilities/embed`, name: 'Embed' },
    { to: `${sampleContentPath}/utilities/figures`, name: 'Figures' },
    { to: `${sampleContentPath}/utilities/flex`, name: 'Flex' },
    { to: `${sampleContentPath}/utilities/grid`, name: 'Grid' },
    { to: `${sampleContentPath}/utilities/sizing`, name: 'Sizing' },
    { to: `${sampleContentPath}/utilities/spacing`, name: 'Spacing' },
    { to: `${sampleContentPath}/utilities/stretchedLink`, name: 'Stretched link' },
    { to: `${sampleContentPath}/utilities/typography`, name: 'Typography' },
    { to: `${sampleContentPath}/utilities/verticalAlign`, name: 'Vertical align' },
    { to: `${sampleContentPath}/utilities/visibility`, name: 'Visibility' }
  ]
};

export default [
  homeRoutes,
  pageRoutes,
  chatRoutes,
  kanbanRoutes,
  calenderRoutes,
  emailRoutes,
  authenticationRoutes,
  ECommerceRoutes,
  widgetsRoutes,
  componentRoutes,
  utilityRoutes,
  pluginRoutes,
  documentationRoutes,
  changelogRoutes
];
